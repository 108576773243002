
const BookingIcon = ({ width = 31, height = 31 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.3186 3.49316H1.68164V29.1513H29.3186V3.49316Z" stroke="currentColor" strokeWidth="2.5" strokeMiterlimit="10" />
            <path d="M1.68164 9.99609H29.3186" stroke="currentColor" strokeWidth="2.5" strokeMiterlimit="10" />
            <path d="M8.5957 0.848633V6.13813" stroke="currentColor" strokeWidth="2.5" strokeMiterlimit="10" />
            <path d="M22.4043 0.848633V6.13813" stroke="currentColor" strokeWidth="2.5" strokeMiterlimit="10" />
            <path d="M10.1406 19.1027L14.0161 22.9786L20.8609 16.1338" stroke="currentColor" strokeWidth="2.5" strokeMiterlimit="10" />
        </svg>
    )
}

export default BookingIcon
