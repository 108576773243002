import { createGlobalStyle, css } from 'styled-components';

import TTNormsProBoldTtf from '../../assets/fonts/ttnorms/TTNormsPro-Bold.ttf';
import TTNormsProBoldWoff from '../../assets/fonts/ttnorms/TTNormsPro-Bold.woff';
import TTNormsProLightTtf from '../../assets/fonts/ttnorms/TTNormsPro-Light.ttf';
import TTNormsProLightWoff from '../../assets/fonts/ttnorms/TTNormsPro-Light.woff';
import TTNormsProMediumTtf from '../../assets/fonts/ttnorms/TTNormsPro-Medium.ttf';
import TTNormsProMediumWoff from '../../assets/fonts/ttnorms/TTNormsPro-Medium.woff';
import TTNormsProRegularTtf from '../../assets/fonts/ttnorms/TTNormsPro-Regular.ttf';
import TTNormsProRegularWoff from '../../assets/fonts/ttnorms/TTNormsPro-Regular.woff';

export const globalStyles = css`
  :root {
    --app-height: 100%;
  }

  @font-face {
    font-family: 'TTNormsPro';
    font-style: normal;
    font-weight: 300;
    src:
            url('${TTNormsProLightTtf}') format('ttf'),
            url('${TTNormsProLightWoff}') format('woff');
  }
  @font-face {
    font-family: 'TTNormsPro';
    font-style: normal;
    font-weight: normal;
    src:
            url('${TTNormsProRegularTtf}') format('ttf'),
            url('${TTNormsProRegularWoff}') format('woff');
  }
  @font-face {
    font-family: 'TTNormsPro';
    font-style: normal;
    font-weight: 600;
    src:
            url('${TTNormsProMediumTtf}') format('ttf'),
            url('${TTNormsProMediumWoff}') format('woff');
  }
  @font-face {
    font-family: 'TTNormsPro';
    font-style: normal;
    font-weight: 700;
    src:
            url('${TTNormsProBoldTtf}') format('ttf'),
            url('${TTNormsProBoldWoff}') format('woff');
  }
  

 * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  * { min-width: 0; min-height: 0; }
  
  html {
    font-size: 62.5%;
  }
  
  html, body, #root {
    width: 100vw;
    /* min-width: 100vh; */
    height: 100vh;
    overflow: hidden;
  }
  
  #root {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  body {
    /* font-family: "Roboto","Segoe UI","Helvetica Neue",Arial,sans-serif; */
    font-family: "TTNormsPro", "Open Sans","Roboto","Segoe UI","Helvetica Neue",Arial,sans-serif;
    width: 100vw;
    height: 100vh;
    font-size: 1.6rem;
    overflow: hidden;
  }

  html, body {
    overscroll-behavior-y: none;
  }
  body {
      position: fixed;
      overflow: hidden;
  }

  html,
  body,
  #root,
  #modals {
      padding: 0;
      margin: 0;
      overflow: hidden;
      width: 100vw;
      height: 100vh;
      height: var(--app-height);
  }
`

export const GlobalStyles = createGlobalStyle`${globalStyles}`

export default globalStyles