import { ProjectService } from '@/api/services/project.service';
import SearchIcon from '@/components/icons/SearchIcon';
import { useToast } from '@/components/shared/toast/useToast';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { useProject } from '@/hooks/useProject';
import { translate } from '@/i18n';
import { useGlobalStore } from '@/stores/globalStore';
import { useProjectStore } from '@/stores/projectStore';
import { lowerCaseString } from '@/utils/helpers/string.helpers';
import debounce from 'lodash/debounce';
import flattenDeep from 'lodash/flattenDeep';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { SearchBookingsIcon, SearchLayersIcon, SearchPlacesIcon, SearchUsersIcon } from '../../../icons/SearchIcons';
import { filterResults } from './Search';
import SearchItem from './SearchItem';

const MobileSearch = ({ handleSearchClose }) => {
    const { workspaceId, projectId } = useProject()
    const { enqueueToast } = useToast()
    const searchRef = useRef(null)
    const fullMode = useProjectStore(state => state.fullMode)
    
    // local state
    const [bookings, setBookings] = useState(true)
    const [places, setPlaces] = useState(true)
    const [user, setUser] = useState(true)
    const [layers, setLayers] = useState(true)
    const [search, setSearch] = useState('')
    const [focus, setFocus] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [data, setData] = useState<any[]>([])

    // data selectors
    const setSelector = useGlobalStore(state => state.setSelector)


    // outside element click listener
    useOnClickOutside(searchRef, () => setFocus(false))

    const fetchData = async () => {
        setLoading(true)
        try {
            const response = await ProjectService.search({
                workspaceId,
                projectId,
                term: String(search),
                point: places,
                user: user || bookings,
                layer: layers
            })

            if (response && response.data) {
                const { search_result } = response.data
                const results: any[] = flattenDeep(search_result)

                let result = results.map(res => {
                    if (res.type === 'user') {
                        res.name = res.display

                        if (bookings) {
                            const pathBookings = Object.keys(res).find(key => key.includes('locations')) || ''
                            const userBookings = JSON.parse(res[pathBookings])

                            const isCorrectSearchBook = lowerCaseString(res.name).includes(lowerCaseString(search))

                            if (!isCorrectSearchBook) {
                                return res
                            }
                            
                            const bookings = userBookings.map(booking => ({
                                type: 'booking',
                                user: res.display,
                                user_id: res.id,
                                name: res.display,
                                id: booking.booking_id,
                                type_uid: booking.place_type,
                                place: booking.place_name,
                                begin: booking.begin,
                                end: booking.end,
                                layer_id: booking.layer_id,
                                place_id: booking.place_id,
                            }))

                            if (!user) {
                                return bookings
                            }

                            return [res, ...bookings]

                        }

                        return res
                    }

                    return res
                }).filter(v => v)

                if (!user) {
                    result = result.filter(item => item.type != 'user')
                }

                const searchResults = flattenDeep(result)

                setData(filterResults(search, searchResults, bookings))
            }
        } catch (e) {
            enqueueToast({ title: 'Ошибка', message: 'Не удалось загрузить данные поиска' }, { variant: 'error' })
        }
        setLoading(false)
    }

    const handleChange = (e) => setSearch(e?.target?.value)
    const handleClose = () => setSelector(null)

    const debouncedResponse = useMemo(() => {
        return debounce(handleChange, 500)
    }, [])

    useEffect(() => {
        if (search) {
            fetchData()
        }
    }, [search, layers, user, places, bookings])

    useEffect(() => {
        return () => debouncedResponse.cancel()
    }, [])

    const renderSearch = () => {
        if (!search || !focus) return null

        if (search.length < 3) {
            return (
                <SearchResults>
                    <NotFound>Минимальное кол-во символов для поиска - 3</NotFound>
                </SearchResults>
            )
        }

        if (isLoading) {
            return (
                <SearchResults>
                    <NotFound>{translate('loading')}....</NotFound>
                </SearchResults>
            )
        }

        if (data && data.length > 0) {
            return (
                <SearchResults>
                    {data.map(item => <SearchItem key={item.name + item.id} handleSearchClose={null} data={item} />)}
                </SearchResults>
            )
        }

        return (
            <SearchResults>
                <NotFound>{translate('no-results')}</NotFound>
            </SearchResults>
        )
    }

    return (
        <Wrapper onFocus={() => setFocus(true)}>
            <SearchBox ref={searchRef}>
                <SearchFieldWrapper>
                    <SearchWrapper onClick={handleClose}>
                        <IconWrapper>
                            <SearchIcon />
                        </IconWrapper>
                        <FormattedMessage id="search">
                            {placeholder => (
                                <SearchInput placeholder={placeholder + '...'} onChange={debouncedResponse} />
                            )}
                        </FormattedMessage>
                    </SearchWrapper>

                    <FiltersWrapper>
                        {fullMode && (
                            <FiltersItem>
                                <SearchBookingsIcon active={bookings} onClick={() => setBookings(!bookings)} />
                            </FiltersItem>
                        )}
                        <FiltersItem>
                            <SearchPlacesIcon active={places} onClick={() => setPlaces(!places)} />
                        </FiltersItem>
                        {fullMode && (
                            <FiltersItem>
                                <SearchUsersIcon active={user} onClick={() => setUser(!user)} />
                            </FiltersItem>
                        )}
                        <FiltersItem>
                            <SearchLayersIcon active={layers} onClick={() => setLayers(!layers)} />
                        </FiltersItem>
                    </FiltersWrapper>
                </SearchFieldWrapper>

                {renderSearch()}
            </SearchBox>
        </Wrapper>
    )
}

export default MobileSearch

const SearchFieldWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-shrink: 0;
`
const IconWrapper = styled.div`
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
`
const FiltersWrapper = styled.div`
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
`

const FiltersItem = styled.div`
    background: #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(:last-child) {
        margin-right: 4px;
    }
`

const NotFound = styled.div`
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
`

const SearchResults = styled.div`
    background: #FFFFFF;
    border-radius: 8px;
    top: 100%;
    margin-top: 6px;
    width: 100%;
    max-height: 500px;
    height: auto;
    box-shadow: 4px 0px 40px rgba(84, 84, 84, 0.25);
    overflow-x: hidden;
    overflow-y: auto;
`

const SearchWrapper = styled.div`
    height: 40px;
    background: #FFFFFF;
    border-radius: 8px;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    width: 100%;

`

const SearchInput = styled.input`
    border: none;
    outline: none;
    height: 100%;
    width: 100%;

    font-weight: 400;
    font-size: 1.6rem;
    line-height: 3.2rem;
    color: #000000;
    padding: 1rem;
`

const SearchBox = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;

`

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    input {
        margin-right: 0.8rem;
    }
`