import { useGlobalStore } from '@/stores/globalStore'
import { useMapStore } from '@/stores/mapStore'
import { useEffect } from 'react'

const MapController = () => {

    const activeLayer = useGlobalStore(state => state.activeLayer)
    const mapLayer = useMapStore(state => state.mapLayer)
    const setPolygon = useMapStore(state => state.setPolygon)

    useEffect(() => {
        const predicate = activeLayer != mapLayer && mapLayer && mapLayer > 0
        const shouldAnimate = Boolean(predicate)

        if (!shouldAnimate) {
            setPolygon(null)
            return
        }

        setPolygon(activeLayer)

    }, [activeLayer, mapLayer])


    return null
}

export default MapController