import { dateFnsLocale } from '@/App';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import useResponsive from '@/hooks/useResponsive';
import { translate } from '@/i18n';
import { easePoly } from 'd3-ease';
import { addMinutes, endOfDay } from 'date-fns';
import { useCallback, useRef, useState } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { usePopper } from 'react-popper';
import { Portal } from 'react-portal';
import { animated, useTransition } from 'react-spring';
import styled from 'styled-components';

const ReportDateSelector = ({ selection, open, setSelection, setOpen }) => {
    const [referenceElement, setReferenceElement] = useState()
    const [popperElement, setPopperElement] = useState()
    const { isPhone } = useResponsive()

    let { styles, attributes } = usePopper(referenceElement, popperElement)

    const ref = useRef<any>()

    const handleSelect = (ranges) => {
        const { startDate, endDate } = ranges.selection
        let end = endDate ? endDate : addMinutes(startDate, 30)

        if (startDate == endDate) {
            end = endOfDay(end)
        }
        
        setSelection({ startDate, endDate: end, key: 'selection' })
    }

    const transitions = useTransition(open, {
        from: { opacity: 0, scale: 0.8 },
        enter: { opacity: 1, scale: 1 },
        leave: { opacity: 0, scale: 0.8 },
        reverse: open,
        config: {
            duration: 300,
            easing: easePoly.exponent(2),
        },
    })

    useOnClickOutside(ref, useCallback(() => setOpen(false), []))

    return (
        <ShowContainer ref={setReferenceElement as any}>
            {transitions(
                (style, item) => item && (
                    <Portal>
                        <ShowPicker
                            ref={setPopperElement as any}
                            style={{ ...styles.popper, ...style }}
                            {...attributes.popper}
                        >
                            <Wrapper ref={ref}>
                                <DateRange
                                    ranges={[selection]}
                                    onChange={handleSelect}
                                    locale={dateFnsLocale}
                                    months={2}
                                    showDateDisplay={false}
                                    direction={isPhone ? 'vertical' : 'horizontal'}
                                />
                                <AcceptButton onClick={() => setOpen(!open)}>{translate('select-period')}</AcceptButton>
                            </Wrapper>
                        </ShowPicker>
                    </Portal>
                )
            )}
        </ShowContainer>
    )
}

export default ReportDateSelector

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    /* align-items: flex-end; */
`

const AcceptButton = styled.button`
    border: none;
    outline: none;
    background-color: #213FDC;
    color: #fff;
    padding: 15px;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
        background-color: #213FDC;
    }
`

const ShowPicker = styled(animated.div)`
    box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px;
    z-index: 12001;
    max-width: 100%;
    max-height: calc(100% - 75px);
    overflow: auto;
`

const ShowContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`