
const ReportsIcon = ({ width = 29, height = 38 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 29 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.1549 36.5634H1.84375V1.43652H21.0878L27.1549 7.50362V36.5634Z" stroke="currentColor" strokeWidth="2.5" strokeMiterlimit="10" />
            <path d="M27.155 7.50362H21.0879V1.43652" stroke="currentColor" strokeWidth="2.5" strokeMiterlimit="10" />
            <path d="M6.13672 30.2061H22.8632" stroke="currentColor" strokeWidth="2.5" />
            <path d="M6.13672 24.3311H18.7319" stroke="currentColor" strokeWidth="2.5" />
            <path d="M6.13672 18.8018H22.8632" stroke="currentColor" strokeWidth="2.5" />
            <path d="M6.13672 13.1338H18.7319" stroke="currentColor" strokeWidth="2.5" />
        </svg>

    )
}

export default ReportsIcon