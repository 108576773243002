
const UsersIcon = ({ width = 31, height = 30 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.5011 16.1885C19.6157 16.1885 22.9513 12.853 22.9513 8.73829C22.9513 4.62369 19.6157 1.28809 15.5011 1.28809C11.3864 1.28809 8.05078 4.62369 8.05078 8.73829C8.05078 12.853 11.3864 16.1885 15.5011 16.1885Z" stroke="currentColor" strokeWidth="2.5" strokeMiterlimit="10" />
            <path d="M29.0224 29.7116C29.0224 22.243 22.9678 16.1885 15.4992 16.1885C8.03066 16.1885 1.97656 22.243 1.97656 29.7116" stroke="currentColor" strokeWidth="2.5" strokeMiterlimit="10" />
        </svg>
    )
}

export default UsersIcon