
const OfficeMapIcon = ({ width = 33, height = 31 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 33 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.9029 18.8863V13.7265H30.9089V1.31104H15.9189H2.08984V24.8857V29.6894H30.8443V23.8791" stroke="currentColor" strokeWidth="2.5" />
            <path d="M2.39258 12.9946H7.42158" stroke="currentColor" strokeWidth="2.5" />
            <path d="M22.9395 9.46191V13.283" stroke="currentColor" strokeWidth="2.5" />
            <path d="M22.9395 1.32666V5.52826" stroke="currentColor" strokeWidth="2.5" />
            <path d="M11.5527 12.9946H30.7265" stroke="currentColor" strokeWidth="2.5" />
        </svg>
    )
}

export default OfficeMapIcon
