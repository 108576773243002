import React, { useEffect } from 'react';
import MapStage from '@/components/shared/map/stage/MapStage';
import MapCoverLayer from './MapCoverLayer';
import PolygonsLayer from '../polygon/PolygonsLayer';
import PointsLayer from '../point/PointsLayer';
import { useLayerView } from '@/api/hooks/useLayerView';
import { useMetadata } from '@/api/hooks/useMetadata';
import { useBookingsForLayer } from '@/api/hooks/useBookingsForLayer';
import { useGlobalStore } from '@/stores/globalStore';
import { useProjectStore } from '@/stores/projectStore';
import { useMapStore } from '@/stores/mapStore';
import { formatLocalDateToAPI } from '@/utils/helpers/dates.helpers';
import { addMinutes } from 'date-fns';
import Tooltip from '../tooltip/Tooltip';

const dispatchLoaded = () => {
  const event = new CustomEvent('map-loaded');
  document.dispatchEvent(event);
};

const MapContainer = () => {
  const activeLayer = useGlobalStore((state) => state.activeLayer);
  const selection = useGlobalStore((state) => state.selection);
  const nodes = useProjectStore((state) => state.nodes);
  const setMapLayer = useMapStore((state) => state.setMapLayer);
  const node = nodes.find((n) => n.id == activeLayer);
  const hasOwnView = node?.ownView;

  const layer = hasOwnView ? node.id : node?.parent || activeLayer;

  const { layerView, isSuccess: layerViewLoading } = useLayerView(
    Number(layer)
  );
  const { metadata } = useMetadata();
  const { data, isSuccess } = useBookingsForLayer(
    Number(layer),
    formatLocalDateToAPI(selection.startDate),
    formatLocalDateToAPI(addMinutes(selection.endDate, -30))
  );

  useEffect(() => {
    setMapLayer(Number(layer));
  }, [layer, activeLayer]);

  useEffect(() => {
    if (isSuccess && layerViewLoading) {
      dispatchLoaded();
    }
  }, [isSuccess, layerViewLoading, activeLayer]);

  return (
    <MapStage>
      <MapCoverLayer view={layerView?.view} />
      <PolygonsLayer polygons={layerView?.polygons} />
      <PointsLayer
        nodes={metadata?.rawNodes}
        points={layerView?.points}
        options={layerView?.options}
        bookings={data?.bookings}
      />
      <Tooltip />
    </MapStage>
  );
};

MapContainer.whyDidYouRender = true;

export default MapContainer;
