import React from 'react'
import {FormGroup} from '@/ui/components/Form/FormGroup';
import {FormLabel} from '@/ui/components/Form/FormLabel';
import {InputField} from '@/ui/components/Field/Input';
import {Field, Formik} from 'formik';
import {Button} from '@/ui/components/Button/Button';
import {useNavigate} from 'react-router-dom';
import {useQueryClient} from 'react-query';
import {useUserStore} from '@/stores/userStore';
import {UserService} from '@/api/services/user.service';
import {translate} from '@/i18n';
import {FormattedMessage} from 'react-intl';

type LoginFormProps = {
    workspaceId: string | null | undefined
    projectId: string | null | undefined
}

const LoginForm: React.FC<LoginFormProps> = ({workspaceId, projectId}) => {
    const router = useNavigate()
    const queryClient = useQueryClient()

    const setUser = useUserStore(state => state.setUser)
    const setRole = useUserStore(state => state.setRole)

    const handleLoginSubmit = async ({
                                         login,
                                         password,
                                         workspaceId
                                     }: { login: string, password: string, workspaceId: string }, {
                                         setSubmitting,
                                         setErrors
                                     }) => {
        setSubmitting(true)
        try {

            const {data} = await UserService.auth({
                login,
                password,
                workspaceId: Number(workspaceId)
            })

            if (data?.ws_auth?.wst) {
                // localStorage.setItem('token', data.ws_auth.wst)
                setUser(data.me_data.ui)
                // if (data.me_data.ui.role === RolesEnum.Admin) {
                //     setRole(RolesEnum.Admin)
                // }
                localStorage.removeItem('saml')
                queryClient.resetQueries()
                router('/')
            } else {
                alert('Unable to login')
            }
        } catch {
            alert('Unable to login')
        }
        setSubmitting(false)
    }

    if (!workspaceId) {
        return (
            <div>
                No workspace ID passed
            </div>
        )
    }

    return (
        <Formik
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={handleLoginSubmit}
            initialValues={{
                login: '',
                password: '',
                workspaceId: workspaceId
            }}
        >
            {({handleSubmit, isSubmitting}) => (
                <form onSubmit={handleSubmit}>
                    <FormGroup>
                        <FormLabel $alternative>
                            {translate("auth_login")}
                        </FormLabel>
                        <FormattedMessage id='auth_login'>
                            {placeholder => (
                                <Field name="login" $transparent $fullWidth type="text" placeholder={placeholder}
                                       component={InputField}/>
                            )}
                        </FormattedMessage>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel $alternative>
                            {translate("auth_password")}
                        </FormLabel>
                        <FormattedMessage id='auth_password'>
                            {placeholder => (
                                <Field name="password" $transparent $fullWidth type="password" placeholder={placeholder}
                                       component={InputField}/>
                            )}
                        </FormattedMessage>

                    </FormGroup>

                    <FormGroup>
                        <Button type="submit">
                            {translate("log-in")}
                        </Button>
                    </FormGroup>
                </form>
            )}
        </Formik>
    )
}

export default LoginForm